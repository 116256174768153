import uri from '../properties.json';
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";   
let config = uri[stage];
export async function findAllClientByDni(dni, paisEmpresaOrigen){
    let responseJson;

    var uriBackend = "";

    if(paisEmpresaOrigen.toLowerCase() == 'argentina' || 
        paisEmpresaOrigen.toLowerCase() == 'colombia' || 
        paisEmpresaOrigen.toLowerCase() == 'peru'){
      uriBackend = config['uri_backend_mcc_arg'];
    }else if (paisEmpresaOrigen.toLowerCase() == 'espania'){
      uriBackend = config['uri_backend_mcc_esp'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'argentina-zolvex'){
        uriBackend = config['uri_backend_mcc_arg_zolvex'];
    }

    await fetch(uriBackend+'/mcc-backend/api/v1/client-all/'+dni,{
    }).then(
      function(response){ return response.json();}
    ).then(
      function(json){
        responseJson = json;           
      }
    ) 
    return responseJson;    
}

export async function adminMscValue(parameter, paisEmpresaOrigen){
    let responseJson;

    var uriBackend = "";

    if(paisEmpresaOrigen.toLowerCase() == 'argentina'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if (paisEmpresaOrigen.toLowerCase() == 'espania'){
        uriBackend = config['uri_backend_mcc_esp'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'peru'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'argentina-zolvex'){
        uriBackend = config['uri_backend_mcc_arg_zolvex'];
    }

    await fetch(uriBackend+'/mcc-backend/api/v1/admin-msc/value/'+parameter,{
    }).then(
        function(response){ return response.json();}
    ).then(
        function(json){
            responseJson = json;
        }
    )
    return responseJson;
}

export async function getTokenNet2Phone(paisEmpresaOrigen){
    let responseJson;

    var uriBackend = "";

    if(paisEmpresaOrigen.toLowerCase() == 'argentina'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if (paisEmpresaOrigen.toLowerCase() == 'espania'){
        uriBackend = config['uri_backend_mcc_esp'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'peru'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'argentina-zolvex'){
        uriBackend = config['uri_backend_mcc_arg_zolvex'];
    }

    await fetch(uriBackend+'/mcc-backend/api/v1/config/token/net2phone',{
        method: 'GET'
    }).then(
        function(response){ return response.json();}
    ).then(
        function(json){
            responseJson = json;
        }
    )
    return responseJson;
}

export async function updateTokenNet2Phone(paisEmpresaOrigen){
    let responseJson;

    var uriBackend = "";

    if(paisEmpresaOrigen.toLowerCase() == 'argentina'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if (paisEmpresaOrigen.toLowerCase() == 'espania'){
        uriBackend = config['uri_backend_mcc_esp'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'peru'){
        uriBackend = config['uri_backend_mcc_arg'];
    }else if(paisEmpresaOrigen.toLowerCase() == 'argentina-zolvex'){
        uriBackend = config['uri_backend_mcc_arg_zolvex'];
    }

    await fetch(uriBackend+'/mcc-backend/api/v1/config/token/net2phone',{
        method: 'PUT'
    }).then(response => { return response.json();}
    ).catch((error, response) => {
        console.error(error);
    }).then((json) => { responseJson = json;}
    )
    return responseJson;
}
