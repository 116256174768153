import React, { Component } from 'react';
import PhoneTable from '../phone-table/PhoneTable';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/free-solid-svg-icons'


import './App.css';
library.add(faPhone)

class App extends Component {
  constructor(props){
    super(props)
    this.state = {}  
               
  }
  getParameterByName(name) {    
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "]");    
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);    
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  render() {  
    let idCuenta = this.getParameterByName("idCuenta");
    let idEmpresa = this.getParameterByName("idEmpresa")!=''?this.getParameterByName("idEmpresa"):0;    
    let dni = this.getParameterByName("dni")!=''?this.getParameterByName("dni"):0; 
    let uri = this.getParameterByName("uri");
    let c = this.getParameterByName("c");       
    let k = this.getParameterByName("k");       
    let a = this.getParameterByName("a");    
    let priorityGroupId = this.getParameterByName("priorityGroupId");
    let campaignId = this.getParameterByName("campaignId");
    let username = this.getParameterByName("username");
    let nombre = this.getParameterByName("nombre");
    let aux3 = this.getParameterByName("aux3");  
    let perfil = this.getParameterByName("perfil");
    let inconcertCampaign = this.getParameterByName("inconcertCampaign");
    let providerCall = this.getParameterByName("providerCall");
    let net2PhoneCampaign = this.getParameterByName("net2PhoneCampaign");
    let paisEmpresaOrigen = this.getParameterByName("paisEmpresaOrigen");
    let net2phoneNroInterno = this.getParameterByName("net2phoneNroInterno");
    let allowPhoneReference = this.getParameterByName("allowPhoneReference");
    uri = uri+"c="+c+"&k="+k+"&a="+a+"&campaignId="+campaignId+"&priorityGroupId="+priorityGroupId+"&username="+username+"&nombre="+nombre+"&aux1="+idCuenta+"&aux2="+idEmpresa+"&aux3="+aux3;    
    return (                                         
      <PhoneTable username={username} idCuenta={idCuenta} uri={uri} idEmpresa={idEmpresa} dni={dni} perfil={perfil}
                  inconcertCampaign={inconcertCampaign} providerCall={providerCall} paisEmpresaOrigen={paisEmpresaOrigen}
                  net2PhoneCampaign={net2PhoneCampaign} net2phoneNroInterno={net2phoneNroInterno}
                  allowPhoneReference={allowPhoneReference}
      />
    );
  }
}
export default App;
