import uri from '../properties.json';
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
let config = uri[stage];

export async function callInconcert(data){

	let responseJson;
	fetch(config['uri_inconcert_local']+"/inconcert/apps/baragent/sdk/makecall?campaign="+data.campaign+"&phone="+data.number+"&iml="+data.imlMakeCall+"&callback=_jqjsp&_1614197232578=",{
    }).then(
      function(response){ return response.json();}
    ).then(
      function(json){
        responseJson = json;           
      }
    ) 
    return responseJson;
}