import uri from '../properties.json';
import * as ClientService from "./ClientService";
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
let config = uri[stage];

export const callNet2Phone = async(data, paisEmpresaOrigen) => {

    const urlencoded = new URLSearchParams();
    urlencoded.append("callerid", data.callerid);
    urlencoded.append("agent", data.agent);
    urlencoded.append("phone", data.phone);
    urlencoded.append("tech", data.tech);
    urlencoded.append("context", data.context);
    urlencoded.append("outqueue", data.outqueue);
    urlencoded.append("destination", data.destination)

    let tokenNet2phone = await ClientService.getTokenNet2Phone(paisEmpresaOrigen);

    let response = await subCallNet2phone(urlencoded, tokenNet2phone);

    if(response.status == 401){
        tokenNet2phone = await ClientService.updateTokenNet2Phone(paisEmpresaOrigen);
        response = await subCallNet2phone(urlencoded, tokenNet2phone);
    }

    return response;
}

const subCallNet2phone = async(urlencoded, tokenNet2phone) => {
    let response;
    await fetch(config['uri_net2phone']+'/Integra/resources/Agents/AgentCall', {
        method: 'POST',
        body: urlencoded,
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization' : 'Basic '+tokenNet2phone.value
            }
        })
        .catch(error => {
            console.error(error);
        })
        .then(async(responseString) => {
            console.log(responseString)
            if (responseString != undefined && responseString.status == 200){
                response = responseString;
                console.log(response)
            } else {
                response = {"error": "error endpoint de net2phone para realizar llamada", "status": 401 };
                console.log(response)
            }
        });
    return response;
}
