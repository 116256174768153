import React from 'react';
import {Route, Switch} from 'react-router-dom'
import App from './components/app/App'

const AppRoutes = () =>
    <App>
        <Switch>
            <Route path="/" component={App}/>
        </Switch>
    </App>
export default AppRoutes;