import uri from '../properties.json';
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
let config = uri[stage];
export async function callDial(data){
  var returnJson;
  await fetch(data['uri'], {
    method: 'get',
    // body: JSON.stringify(data),
    // headers: {
    // 'Accept': 'application/json',
    // 'Content-Type': 'application/json; charset=UTF-8'
    // },
  })
  .then((response) => response.json())
  .then(async(responseJson) => { 
    returnJson = JSON.stringify(responseJson);
    return responseJson;     
  })    
  .catch((error) => {
  console.error(error);
  });
  return returnJson;     
}