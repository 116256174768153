import uri from '../properties.json';
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";   
let config = uri[stage];
export async function phoneScore(idCuenta, paisEmpresaOrigen){    
    let returnJson;
    await fetch(config['uri_backend_score']+'/phone/score/'+idCuenta+'/'+paisEmpresaOrigen, {
          method: 'get',        
    })
    .then((response) => response.json())
    .then(async(responseJson) => { 
      var i = 0;
      var red = 0;
      var green = 0;
      var blue = 0;    
      console.log(responseJson.length);  
      var max = (responseJson.length - 1);  
      let allTimeSlot = [];                    
      if (i === 0) {
        allTimeSlot = await timeSlot();            
      }
      let lengthSlot = allTimeSlot.length;
      await Promise.all(responseJson.map(async (element) => {               
        //besta_description 
        var description = "";       
        if (element.besta !== 0) {                    
          for (let index = 0; index < lengthSlot; index++) {
            if (element.besta === allTimeSlot[index].number) {
              description = allTimeSlot[index].description;     
            }                    
          }
          element.besta_description = description; 
        }else{
          element.besta_description = "";
        }        
        //status_call        
          responseJson[i].status_call = true;  
        //gradiente
        if (responseJson[i].status === "inicial" || responseJson[i].active === 0 || responseJson[i].scoring === 0) {
          red = 244;
          green = 58;
          blue = 58;
        }else{
          if (max < 3) {            
            switch(i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
              break;
              case 1:
                red = 224;
                green = 224;
                blue = 57;
              break;
              case 2:
                red = 224;
                green = 58;
                blue = 58;
              break;
              default:
              // code block
            }
          }if (max <= 5) {
            if(max === 3){
              switch(i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 180;
                green = 224;
                blue = 57;
                break;
                case 2:
                red = 224;
                green = 224;
                blue = 58;
                break;
                case 3:
                red = 224;
                green = 58;
                blue = 58;
                break;              
                default:
                // code block
              }
            }else if(max === 4){
              switch(i) {
              case 0:
              red = 118;
              green = 224;
              blue = 57;
              break;
              case 1:
              red = 180;
              green = 224;
              blue = 57;
              break;
              case 2:
              red = 224;
              green = 224;
              blue = 58;
              break;
              case 3:
              red = 224;
              green = 150;
              blue = 58;
              break;
              case 4:
              red = 224;
              green = 58;
              blue = 58;
              break;            
              default:
              // code block
              }
            }else if(max === 5){
              switch(i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 180;
                green = 224;
                blue = 57;
                break;
                case 2:
                red = 224;
                green = 224;
                blue = 58;
                break;
                case 3:
                red = 224;
                green = 200;
                blue = 58;
                break;
                case 4:
                red = 224;
                green = 160;
                blue = 58;
                break;
                case 5:
                red = 224;
                green = 120;
                blue = 58;
                break;
                default:
                // code block
              }
            }          
          }else if(max < 10){
            if (max === 6) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 146;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 190;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 224;
                green = 160;
                blue = 57;
                break; 
                case 5:          
                red = 224;
                green = 100;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 58;
                blue = 57;
                break;         
                default:
                break;
              }
            }else if(max === 7){
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 146;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 190;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 224;
                green = 160;
                blue = 57;
                break; 
                case 5:          
                red = 224;
                green = 100;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 80;
                blue = 57;
                break; 
                case 7:          
                red = 224;
                green = 58;
                blue = 57;
                break;        
                default:
                break;
              }
            }else if(max === 8){
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 146;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 190;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 224;
                green = 160;
                blue = 57;
                break; 
                case 5:          
                red = 224;
                green = 130;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 100;
                blue = 57;
                break; 
                case 7:          
                red = 224;
                green = 80;
                blue = 57;
                break;
                case 8:          
                red = 224;
                green = 58;
                blue = 57;
                break;        
                default:
                break;
              }
            }else if(max === 9){
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 146;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 190;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 224;
                green = 207;
                blue = 57;
                break; 
                case 5:          
                red = 224;
                green = 185;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 157;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 129;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 109;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 58;
                blue = 58;
                break;        
                default:
                break;
              }
            }                   
          }else if(max <= 25){
            if (max === 10) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 150;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 170;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 180;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 150;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 58;
                blue = 58;
                break;                
                default:
                break;
              }
            }else if (max === 11) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 150;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 170;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 180;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 150;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 58;
                blue = 58;
                break;               
                default:
                break;
              }              
            }else if (max === 12) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 150;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 170;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 180;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 150;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 120;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 58;
                blue = 58;
                break;               
                default:
                break;
                }              
            }else if (max === 13) {          
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 150;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 170;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 180;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 160;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 120;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 58;
                blue = 58;
                break;                
                default:
                break;
              }              
            }else if (max === 14) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 170;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 190;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 170;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 130;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 58;
                blue = 58;
                break;               
                default:
                break;
              }              
            }else if (max === 15) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 170;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 190;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 170;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 130;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 60;
                blue = 58;
                break; 
                case 15:          
                red = 224;
                green = 58;
                blue = 58;
                break;              
                default:
                break;
              }              
            }else if (max === 16) {            
              switch (i) {
              case 0:
              red = 118;
              green = 224;
              blue = 57;
              break;
              case 1:
              red = 130;
              green = 224;
              blue = 57;
              break; 
              case 2:
              red = 140;
              green = 224;
              blue = 57;
              break;
              case 3:          
              red = 150;
              green = 224;
              blue = 57;
              break;  
              case 4:          
              red = 170;
              green = 224;
              blue = 57;
              break; 
              case 5:          
              red = 200;
              green = 224;
              blue = 57;
              break;
              case 6:          
              red = 224;
              green = 224;
              blue = 57;
              break;  
              case 7:          
              red = 224;
              green = 200;
              blue = 57;
              break; 
              case 8:          
              red = 224;
              green = 190;
              blue = 57;
              break; 
              case 9:          
              red = 224;
              green = 180;
              blue = 58;
              break; 
              case 10:          
              red = 224;
              green = 170;
              blue = 58;
              break; 
              case 11:          
              red = 224;
              green = 150;
              blue = 58;
              break;
              case 12:          
              red = 224;
              green = 120;
              blue = 58;
              break;
              case 13:          
              red = 224;
              green = 100;
              blue = 58;
              break; 
              case 14:          
              red = 224;
              green = 80;
              blue = 58;
              break; 
              case 15:          
              red = 224;
              green = 70;
              blue = 58;
              break;  
              case 16:          
              red = 224;
              green = 58;
              blue = 58;
              break;            
              default:
              break;
              }              
            }else if (max === 17) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 170;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 200;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 190;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 170;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 150;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 110;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 15:          
                red = 224;
                green = 80;
                blue = 58;
                break;  
                case 16:          
                red = 224;
                green = 70;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 58;
                blue = 58;
                break;           
                default:
                break;
              }              
            }else if (max === 18) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 160;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 170;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 180;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 200;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 200;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 160;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 140;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 120;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 15:          
                red = 224;
                green = 90;
                blue = 58;
                break;  
                case 16:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 70;
                blue = 58;
                break;
                case 18:          
                red = 224;
                green = 58;
                blue = 58;
                break;           
                default:
                break;
              }              
            }else if (max === 19) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 160;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 170;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 180;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 200;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 200;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 190;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 170;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 160;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 140;
                blue = 58;
                break; 
                case 15:          
                red = 224;
                green = 120;
                blue = 58;
                break;  
                case 16:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 90;
                blue = 58;
                break;
                case 18:          
                red = 224;
                green = 80;
                blue = 58;
                break;  
                case 19:          
                red = 224;
                green = 58;
                blue = 58;
                break;         
                default:
                break;
              }              
            }else if (max === 20) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 160;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 170;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 180;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 190;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 200;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 200;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 190;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 170;
                blue = 58;
                break;
                case 13:          
                red = 224;
                green = 160;
                blue = 58;
                break; 
                case 14:          
                red = 224;
                green = 140;
                blue = 58;
                break; 
                case 15:          
                red = 224;
                green = 120;
                blue = 58;
                break;  
                case 16:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 90;
                blue = 58;
                break;
                case 18:          
                red = 224;
                green = 80;
                blue = 58;
                break;  
                case 19:          
                red = 224;
                green = 70;
                blue = 58;
                break;
                case 20:          
                red = 224;
                green = 58;
                blue = 58;
                break;         
                default:
                break;
              }              
            }else if(max <= 25){
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 160;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 170;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 210;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 190;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 170;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 160;
                blue = 58;
                break;   
                case 13:          
                red = 224;
                green = 150;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 140;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 130;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 120;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 110;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 90;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 60;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 50;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 50;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 50;
                blue = 58;
                break;    
                default:
                break;
              } 
            }            
          }else if(max <= 40){
            if (max === 26) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 130;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 140;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 150;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 160;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 170;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 224;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 224;
                green = 210;
                blue = 57;
                break; 
                case 8:          
                red = 224;
                green = 200;
                blue = 57;
                break; 
                case 9:          
                red = 224;
                green = 190;
                blue = 58;
                break; 
                case 10:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 11:          
                red = 224;
                green = 170;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 160;
                blue = 58;
                break;   
                case 13:          
                red = 224;
                green = 150;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 140;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 130;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 120;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 110;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 90;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 80;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 60;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 50;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 50;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 50;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 50;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 50;
                blue = 58;
                break;     
                default:
                break;
              } 
            }else if (max === 27) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 128;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 138;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 148;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 158;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 168;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 178;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 188;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 198;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 208;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 218;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 224
                green = 214;
                blue = 58;
                break;
                case 12:          
                red = 224;
                green = 204;
                blue = 58;
                break;   
                case 13:          
                red = 224;
                green = 194;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 184;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 174;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 164;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 154;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 144;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 124;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 114;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 104;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 100;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 90;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 70;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 50;
                blue = 58;
                break;     
                default:
                break;
              }              
            }else if (max === 28) {
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 126;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 118;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 110;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 90;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 50;
                blue = 58;
                break;     
                default:
                break;
              }               
            }else if (max === 29) {          
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 70;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 60;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 50;
                blue = 58;
                break;    
                default:
                break;
              }              
            }else if (max === 30) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 75;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 70;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 60;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 50;
                blue = 58;
                break;    
                default:
                break;
              }             
            }else if (max === 31) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 75;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 70;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 65;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 60;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 50;
                blue = 58;
                break;    
                default:
                break;
              }             
            }else if (max === 32) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 77;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 75;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 72;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 68;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 66;
                blue = 58;
                break; 
                case 32:          
                red = 224;
                green = 50;
                blue = 58;
                break;   
                default:
                break;
              }              
            }else if (max === 33) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 77;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 75;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 72;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 68;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 66;
                blue = 58;
                break; 
                case 32:          
                red = 224;
                green = 64;
                blue = 58;
                break; 
                case 33:          
                red = 224;
                green = 50;
                blue = 58;
                break;  
                default:
                break;
              }              
            }else if (max === 34) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 77;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 75;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 72;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 68;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 66;
                blue = 58;
                break; 
                case 32:          
                red = 224;
                green = 64;
                blue = 58;
                break; 
                case 33:          
                red = 224;
                green = 62;
                blue = 58;
                break;
                case 34:          
                red = 224;
                green = 50;
                blue = 58;
                break;   
                default:
                break;
              }              
            }else if (max === 35) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 77;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 75;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 72;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 67;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 66;
                blue = 58;
                break; 
                case 32:          
                red = 224;
                green = 64;
                blue = 58;
                break; 
                case 33:          
                red = 224;
                green = 62;
                blue = 58;
                break;
                case 34:          
                red = 224;
                green = 60;
                blue = 58;
                break;
                case 35:          
                red = 224;
                green = 50;
                blue = 58;
                break;   
                default:
                break;
              }           
            }else if (max <= 40) {            
              switch (i) {
                case 0:
                red = 118;
                green = 224;
                blue = 57;
                break;
                case 1:
                red = 126;
                green = 224;
                blue = 57;
                break; 
                case 2:
                red = 134;
                green = 224;
                blue = 57;
                break;
                case 3:          
                red = 142;
                green = 224;
                blue = 57;
                break;  
                case 4:          
                red = 150;
                green = 224;
                blue = 57;
                break; 
                case 5:          
                red = 158;
                green = 224;
                blue = 57;
                break;
                case 6:          
                red = 166;
                green = 224;
                blue = 57;
                break;  
                case 7:          
                red = 174;
                green = 224;
                blue = 57;
                break; 
                case 8:          
                red = 182;
                green = 224;
                blue = 57;
                break; 
                case 9:          
                red = 190;
                green = 224;
                blue = 58;
                break; 
                case 10:          
                red = 198;
                green = 224;
                blue = 58;
                break; 
                case 11:          
                red = 206
                green = 216;
                blue = 58;
                break;
                case 12:          
                red = 212;
                green = 208;
                blue = 58;
                break;   
                case 13:          
                red = 220;
                green = 200;
                blue = 58;
                break;
                case 14:          
                red = 224;
                green = 192;
                blue = 58;
                break;
                case 15:          
                red = 224;
                green = 196;
                blue = 58;
                break; 
                case 16:          
                red = 224;
                green = 188;
                blue = 58;
                break; 
                case 17:          
                red = 224;
                green = 180;
                blue = 58;
                break; 
                case 18:          
                red = 224;
                green = 172;
                blue = 58;
                break; 
                case 19:          
                red = 224;
                green = 166;
                blue = 58;
                break; 
                case 20:          
                red = 224;
                green = 158;
                blue = 58;
                break; 
                case 21:          
                red = 224;
                green = 150;
                blue = 58;
                break; 
                case 22:          
                red = 224;
                green = 142;
                blue = 58;
                break; 
                case 23:          
                red = 224;
                green = 134;
                blue = 58;
                break; 
                case 24:          
                red = 224;
                green = 120;
                blue = 58;
                break;
                case 25:          
                red = 224;
                green = 100;
                blue = 58;
                break;
                case 26:          
                red = 224;
                green = 80;
                blue = 58;
                break;
                case 27:          
                red = 224;
                green = 77;
                blue = 58;
                break;
                case 28:          
                red = 224;
                green = 75;
                blue = 58;
                break; 
                case 29:          
                red = 224;
                green = 72;
                blue = 58;
                break;
                case 30:          
                red = 224;
                green = 67;
                blue = 58;
                break;
                case 31:          
                red = 224;
                green = 66;
                blue = 58;
                break; 
                case 32:          
                red = 224;
                green = 65;
                blue = 58;
                break; 
                case 33:          
                red = 224;
                green = 64;
                blue = 58;
                break;
                case 34:          
                red = 224;
                green = 64;
                blue = 58;
                break;
                case 35:          
                red = 224;
                green = 63;
                blue = 58;
                break;
                case 36:          
                red = 224;
                green = 62;
                blue = 58;
                break; 
                case 37:          
                red = 224;
                green = 60;
                blue = 58;
                break; 
                case 38:          
                red = 224;
                green = 56;
                blue = 58;
                break; 
                case 39:          
                red = 224;
                green = 54;
                blue = 58;
                break; 
                case 40:          
                red = 224;
                green = 50;
                blue = 58;
                break;    
                default:
                break;
              }            
            }             
          }
        }        
        const styles ={
          one: {
            background: 'rgb('+red+', '+green+', '+blue+')',
          },
        };
        responseJson[i].background = {styles};        
        //type_description        
        switch (responseJson[i].type) {
          case "M":
            responseJson[i].type_description = "Movil";            
            break;
          case "L":
            responseJson[i].type_description = "Linea";
            break;
          default:
            break;
        }        
        i++;
      }));                 
        returnJson = responseJson; 
        return responseJson;
    })    
    .catch((error) => {
      console.error(error);
    });
    return returnJson;
}
async function timeSlot(){
    let responseJson;
    await fetch(config['uri_backend_score']+'/timeslot',{
    }).then(
      function(response){ return response.json();}
    ).then(
      function(json){
        responseJson = json;           
      }
    ) 
    return responseJson;    
}
export async function savePhone(data){
  var responseSave;
  await fetch(config['uri_backend_score']+'/phone/register', {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8'
    },
  })
  .then((response) => response.json())
  .then(async(responseJson) => { 
    responseSave = responseJson;
  })    
  .catch((error) => {
  console.error(error);
  });

  return responseSave;
}
export async function updatePhone(data){
  var responseUpdate;
  await fetch(config['uri_backend_score']+'/phone/u1', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8'
    },
  })
  .then((response) => response.json())
  .then(async(responseJson) => { 
    responseUpdate = responseJson;
  })    
  .catch((error) => {
  console.error(error);
  });
  return responseUpdate;
}


