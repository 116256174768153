import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import PhoneTable from './components/phone-table/PhoneTable';
import * as serviceWorker from './serviceWorker';
// import PhoneTableService from './services/PhoneTableService';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from './routes';
import { BrowserRouter as Router} from 'react-router-dom'

ReactDOM.render(<Router><AppRoutes/></Router>, document.getElementById('root'));
serviceWorker.unregister();
