import uri from "../properties.json";
const stage = process.env.REACT_APP_STAGE?process.env.REACT_APP_STAGE:"dev";
let config = uri[stage];

export const sendEmailAWS = async(json) => {

    let response

    await fetch(config['uri_middlewate_mcc']+"/sendEmailConfirmation", {
        method: 'POST',
        body: JSON.stringify(json),
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    }).catch(error => {
        console.error(error);
    }).then(async(responseString) => {
        console.log(responseString)
        if (responseString != undefined && responseString.status == 200){
            response = responseString;
            console.log(response)
        } else {
            console.log("error response: "+response)
        }
    });
    return response;
}
